
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { AuthStoreMixin } from "@/mixins/Stores/Auth/AuthStoreMixin";
import NotAuthenticatedLayout from "@/layout/NotAuthenticatedLayout.vue";

@Component({
  components: { NotAuthenticatedLayout },
})
export default class LoginWithCallback extends mixins(AuthStoreMixin) {
  protected loading = true;
  protected errorStatus = -1;

  protected loginWith(provider: string, query: any): void {
    this.loginWithAuthAction({
      data: {
        provider,
        query,
      },
    }).catch((error: any) => {
      this.errorStatus = error.response.status;
      this.loading = false;
    });
  }

  created(): void {
    this.loginWith(this.$route.params.provider, this.$route.query);
  }
}
